<template>
  <v-container fluid>
    <v-dialog v-model="showCartDialog" width="30%" persistent>
      <v-card height="250px" dense style="font-size: 10px">
        <v-card-title center>{{ selectedItem.product }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row style="padding: 0px">
              <v-text-field
                v-model="selectedCartQuantity"
                type="number"
                label="Quantity"
                :error-messages="errorMessages"
                filled
                autofocus
              >
              </v-text-field>
            </v-row>
            <v-row style="padding: 0px">
              <v-col>
                <v-btn block tile depressed @click="cancelAddToCart">
                  Cancel
                </v-btn>
              </v-col>
              <v-col>
                <v-btn block depressed tile @click="submitAddToCart">
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>Cart</v-row>
    <v-row>
      <v-layout child-flex>

      <v-simple-table fixed-header height="160px" width="100%" dense fill-width>
        <thead>
          <tr>
            <th>Product</th>
            <th>Material</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Total</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in cart.items" :key="i">
            <td>{{ item.product }}</td>
            <td>{{ item.material }}</td>
            <td style="white-space: nowrap">
              <v-btn
                @click="$store.commit('deductItemFromCart', item)"
                icon
                color="orange"
                :disabled="$store.getters.amountInCart(item) === 0"
                style="padding: 0px"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              {{ $store.getters.amountInCart(item) }}
              <v-btn
                @click="$store.dispatch('addItemToCart', item)"
                icon
                color="orange"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </td>
            <td>{{ item.price | currency }}</td>
            <td>{{ item.total | currency }}</td>
            <td>
              <v-btn
                @click="$store.commit('removeItemFromCart', item)"
                icon
                color="orange"
                style="padding-right: 0px"
              >
                <v-icon style="padding: 0px">mdi-delete</v-icon>
              </v-btn>
              <v-btn
                @click="popCartDialog(item)"
                icon
                color="orange"
                style="padding-left: 0px"
              >
                <v-icon>mdi-cart</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      </v-layout>
    </v-row>
  </v-container>
</template>

<script>
export default {
  computed: {
    cart() {
      return this.$store.state.order;
    },
  },
  data() {
    return {
      showCartDialog: false,
      selectedItem: {},
      selectedCartQuantity: "",
      errorMessages: [],
    };
  },
  methods: {
    popCartDialog(item) {
      this.selectedItem = item;
      const currentAmount = this.$store.getters.amountInCart(item);
      if (currentAmount !== 0) this.selectedCartQuantity = currentAmount;
      this.showCartDialog = true;
    },
    cancelAddToCart() {
      this.showCartDialog = false;
      this.selectedItem = {};
      this.selectedCartQuantity = "";
      this.errorMessages = [];
    },
    submitAddToCart() {
      if (!this.selectedCartQuantity || this.selectedCartQuantity < 0) {
        this.errorMessages = ["Please enter a quantity 0 or greater"];
        return;
      }
      this.$store.dispatch("setItemInCart", {
        item: this.selectedItem,
        quantity: this.selectedCartQuantity,
      });
      this.showCartDialog = false;
      this.selectedItem = {};
      this.selectedCartQuantity = "";
      this.errorMessages = [];
    },
  },
};
</script>